import React, { FC, Fragment } from 'react'
import { HeaderNotice } from '@/network/graphql.g'
import HeaderPromoCustomize from '@/components/molecules/headerPromo/headerPromoCustomize'
import { useAsset } from '@/providers/assetsProvider'
import { useTowerCheckout } from '@/providers/experimentProvider'
import HeaderPromoDefault from '@/components/molecules/headerPromo/headerPromoDefault'

type HeaderPromoProps = {
  headerNotice: HeaderNotice
  showHeaderPromo: boolean
  setShowHeaderPromo: React.Dispatch<React.SetStateAction<boolean>>
}

const HeaderPromo: FC<HeaderPromoProps> = ({
  headerNotice,
  showHeaderPromo,
  setShowHeaderPromo
}) => {
  const { isShutDownCountry, isCartProcessStep } = useAsset()
  const hasNoticeText = !!headerNotice?.text?.length
  const isTowerCheckout = useTowerCheckout()

  const shouldDisplayCustomPromo =
    (hasNoticeText || (isShutDownCountry && hasNoticeText)) &&
    showHeaderPromo &&
    !(isCartProcessStep && isTowerCheckout)

  const shouldDisplayDefaultPromo =
    (!shouldDisplayCustomPromo &&
      (!isShutDownCountry || headerNotice?.text?.length === 0)) ||
    (isCartProcessStep && isTowerCheckout)

  return (
    <Fragment>
      {shouldDisplayCustomPromo && (
        <HeaderPromoCustomize
          headerNotice={headerNotice}
          setShowHeaderPromo={setShowHeaderPromo}
        />
      )}
      {shouldDisplayDefaultPromo && (
        <HeaderPromoDefault
          display={{
            tablet: 'flex',
            desktop: 'flex',
            mobile: isCartProcessStep && isTowerCheckout ? 'flex' : 'none'
          }}
        />
      )}
    </Fragment>
  )
}

export default HeaderPromo
